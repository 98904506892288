import { ColumnType } from '../util/types'

// 刀具库设置
export const knivesColumns: ColumnType[] = [
  // 编号
  {
    label: 'sideHoleMachine.knifes.number',
    props: 'knifeName',
    canEdit: false,
    width: 90,
    align: 'center',
  },
  // 刀宽
  {
    label: 'sideHoleMachine.knifes.diameter',
    props: 'diameter',
    canEdit: true,
    width: 90,
    isInputCheck: true,
    align: 'center',
  },
  // 类型
  {
    label: 'sideHoleMachine.knifes.type',
    props: 'type',
    isCustom: true,
    width: 380,
    align: 'center',
  },
  // 快速进给速度
  {
    label: 'sideHoleMachine.knifes.moveSpeed',
    props: 'moveSpeed',
    canEdit: true,
    isInputCheck: true,
    align: 'center',
  },
  // 加工速度
  {
    label: 'sideHoleMachine.knifes.doSpeed',
    props: 'doSpeed',
    canEdit: true,
    isInputCheck: true,
    align: 'center',
  },
  // 换刀开始代码
  {
    label: 'sideHoleMachine.knifes.startCode',
    props: 'startCode',
    canEdit: true,
    isTextarea: true,
    align: 'center',
  },
  // 换刀结束代码
  {
    label: 'sideHoleMachine.knifes.endCode',
    props: 'endCode',
    canEdit: true,
    isTextarea: true,
    align: 'center',
  },
]

//代码部分
export const codeList = [
  // 设备启动代码
  {
    title: 'sideHoleMachine.equipmentParams.equipStartCode',
    key: 'beginCode',
  },
  // 设备暂停代码
  {
    title: 'sideHoleMachine.equipmentParams.equipPauseCode',
    key: 'pauseCode',
  },
  // 设备结束代码
  {
    title: 'sideHoleMachine.equipmentParams.equipEndCode',
    key: 'endCode',
  },
  // 左面开始代码
  {
    title: 'sideHoleMachine.equipmentParams.leftTurnCode',
    key: 'leftTurnCode',
  },
  // 左面结束代码
  {
    title: 'sideHoleMachine.equipmentParams.leftTurnEndCode',
    key: 'leftTurnEndCode',
  },
  // 下面开始代码
  {
    title: 'sideHoleMachine.equipmentParams.downTurnCode',
    key: 'backTurnCode',
  },
  // 下面结束代码
  {
    title: 'sideHoleMachine.equipmentParams.downTurnEndCode',
    key: 'backTurnEndCode',
  },
  // 右面开始代码
  {
    title: 'sideHoleMachine.equipmentParams.rightTurnCode',
    key: 'rightTurnCode',
  },
  // 右面结束代码
  {
    title: 'sideHoleMachine.equipmentParams.rightTurnEndCode',
    key: 'rightTurnEndCode',
  },
  // 上面开始代码
  {
    title: 'sideHoleMachine.equipmentParams.frontTurnCode',
    key: 'frontTurnCode',
  },
  // 上面结束代码
  {
    title: 'sideHoleMachine.equipmentParams.frontTurnEndCode',
    key: 'frontTurnEndCode',
  },
]

// 打孔设置
export const holeSetting = [
  // 移动代码
  {
    title: 'sideHoleMachine.equipmentParams.moveCode',
    key: 'holeMove',
  },
  // 加工代码
  {
    title: 'sideHoleMachine.equipmentParams.punchCode',
    key: 'punchHole',
  },
  // 摆动代码
  {
    title: 'sideHoleMachine.equipmentParams.wiggleCode',
    key: 'swingCode',
  },
  // 气缸收回指令
  {
    title: 'sideHoleMachine.equipmentParams.cylinderbackDirective',
    key: 'holeStop',
  },
]

// 拉槽设置
export const slotSetting = [
  // 空走
  {
    title: 'sideHoleMachine.equipmentParams.free',
    key: 'freeSlot',
  },
  // 加工部分
  {
    title: 'sideHoleMachine.equipmentParams.punchPart',
    key: 'punchSlot',
  },
  // 气缸收回指令
  {
    title: 'sideHoleMachine.equipmentParams.cylinderbackDirective',
    key: 'stopCode',
  },
]

//轴相关
export const axisList = [
  // 横向纵向平面
  {
    title: 'sideHoleMachine.equipmentParams.horVerCutFace',
    key: 'horVerCutFace',
  },
  // 横向升降平面
  {
    title: 'sideHoleMachine.equipmentParams.horCutFace',
    key: 'horCutFace',
  },
  // 纵向升降平面
  {
    title: 'sideHoleMachine.equipmentParams.verCutFace',
    key: 'verCutFace',
  },
]

// 拉米诺设置
export const lmnSetting = [
  // 空走
  {
    title: 'sideHoleMachine.equipmentParams.free',
    key: 'lmnFree',
  },
  // 加工部分
  {
    title: 'sideHoleMachine.equipmentParams.punchPart',
    key: 'lmnCut',
  },
  // 加工平面代码
  {
    title: 'sideHoleMachine.equipmentParams.cutFaceCode',
    key: 'lmnCutFace',
  },
  // 气缸收回代码
  {
    title: 'sideHoleMachine.equipmentParams.cylinderbackCode',
    key: 'lmnStop',
  },
]

// 坐标设置
export const pointSetting = [
  // 横向坐标
  {
    title: 'sideHoleMachine.coordinate.xPointSymbol',
    key: 'yPointSymbol',
  },
  // 纵向坐标
  {
    title: 'sideHoleMachine.coordinate.yPointSymbol',
    key: 'zPointSymbol',
  },
  // 升降坐标
  {
    title: 'sideHoleMachine.coordinate.yPointSymbol',
    key: 'xPointSymbol',
  },
]
