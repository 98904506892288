<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      name: 'default',
    }
  },
}
</script>
