<template>
  <div class="item">
    <div class="title">
      <span class="title_text">{{ $props.title }}</span>
      <slot name="title"></slot>
    </div>
    <slot name="body"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: '示例',
    },
  },
}
</script>

<style lang="less" scoped>
.item {
  margin-top: 40px;
  .title {
    width: 100%;
    margin-bottom: 20px;
    &_text {
      color: #000;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
</style>
