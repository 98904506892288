<template>
  <input
    ref="input"
    :style="style[size]"
    v-bind="$attrs"
    :value="value"
    :placeholder="placeholder"
    @input="inputChange"
  />
</template>

<script>
import { regReplaceInputNumberInputChange } from '@/util/regReplace'

export default {
  props: {
    // 双向绑定value值
    value: {
      type: [String, Number],
    },
    // 最大数
    max: {
      type: String,
    },
    // 最小数
    min: {
      type: String,
      default: '0',
    },
    // 是否是整数
    int: {
      type: Boolean,
      default: true,
    },
    // 几位小数
    decimalBit: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: 'small',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      style: {
        small: { height: '32px' },
        mini: { height: '24px' },
      },
    }
  },
  methods: {
    inputChange(e) {
      let { value } = e.target
      const maxLen = String(this.max).length
      const s = !this.int
        ? `(^\\-?)\\D*(\\d{0,${maxLen}})\\d*(\\.?)(\\d{0,${this.decimalBit}})\\d*`
        : `\\D*(\\d{0,${maxLen}})\\d*(\\.?)(\\d{0,${this.decimalBit}})\\d*`
      const d = !this.int ? '$1$2$3$4' : '$1$2$3'
      const reg = RegExp(s)
      value = regReplaceInputNumberInputChange(value, reg, d)
      if (+value < +this.min || +value > +this.max) {
        value = this.value
      }
      if (+value === +this.min || +value === +this.max) {
        value = value.replace(/\./g, '')
      }
      if (this.int && !this.decimalBit) value = value.replace(/\./g, '')
      this.setInputVal()
      this.$emit('update:value', value)
    },
    setInputVal() {
      const input = this.$refs['input']
      if (!input) return
      if (input.value == this.value) return
      input.value = this.value
    },
  },
}
</script>

<style lang="less" scoped>
input {
  box-sizing: border-box;
  width: 100%;
  padding: 0 11px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}
input:focus {
  border-color: #40a9ff;
  outline: none;
  box-shadow: 0 0 3px 1px rgba(62, 166, 251, 0.5);
}
input:hover {
  border-color: #40a9ff;
}
</style>
