import { ColumnType } from '../util/types'

export const knivesColumns: ColumnType[] = [
  //编号
  {
    label: 'sideHoleMachine.knifes.number',
    props: 'id',
    canEdit: false,
    width: 90,
    align: 'center',
  },
  // 刀宽
  {
    label: 'sideHoleMachine.knifes.diameter',
    props: 'diameter',
    canEdit: true,
    width: 90,
    isInputCheck: true,
    align: 'center',
  },
  // 类型
  {
    label: 'sideHoleMachine.knifes.type',
    props: 'type',
    canEdit: true,
    align: 'center',
  },
  // 快速进给速度
  {
    label: 'sideHoleMachine.knifes.moveSpeed',
    props: 'moveSpeed',
    canEdit: true,
    isInputCheck: true,
    align: 'center',
  },
  // 加工速度
  {
    label: 'sideHoleMachine.knifes.doSpeed',
    props: 'doSpeed',
    canEdit: true,
    isInputCheck: true,
    align: 'center',
  },
  // 换刀开始代码
  {
    label: 'sideHoleMachine.knifes.startCode',
    props: 'startCode',
    canEdit: true,
    isTextarea: true,
    align: 'center',
  },
  // 换刀结束代码
  {
    label: 'sideHoleMachine.knifes.endCode',
    props: 'endCode',
    canEdit: true,
    isTextarea: true,
    align: 'center',
  },
]
export const codeList = [
  // 设备启动代码
  {
    title: 'sideHoleMachine.equipmentParams.equipStartCode',
    key: 'beginCode',
  },
  // 设备暂停代码
  {
    title: 'sideHoleMachine.equipmentParams.equipPauseCode',
    key: 'pauseCode',
  },
  // 翻面代码 - 上
  {
    title: 'sideHoleMachine.equipmentParams.topFlipCode',
    key: 'frontTurnCode',
  },
  // 翻面代码 - 下
  {
    title: 'sideHoleMachine.equipmentParams.downFlipCode',
    key: 'backTurnCode',
  },
  // 翻面代码 - 左
  {
    title: 'sideHoleMachine.equipmentParams.leftFlipCode',
    key: 'leftTurnCode',
  },
  // 翻面代码 - 右
  {
    title: 'sideHoleMachine.equipmentParams.rightFlipCode',
    key: 'rightTurnCode',
  },
  // 设备结束代码
  {
    title: 'sideHoleMachine.equipmentParams.equipEndCode',
    key: 'endCode',
  },
  // 气缸推出代码
  {
    title: 'sideHoleMachine.equipmentParams.cylinderOutCode',
    key: 'startCode',
  },
  // 气缸收回代码
  {
    title: 'sideHoleMachine.equipmentParams.cylinderbackCode',
    key: 'stopCode',
  },
  // 移动指令
  {
    title: 'sideHoleMachine.equipmentParams.moveDirective',
    key: 'moveCode',
  },
]

export const axisList = [
  // 移动轴
  {
    title: 'sideHoleMachine.equipmentParams.moveAxisCode',
    key: 'moveAxisCode',
  },
  // 气缸推动轴
  {
    title: 'sideHoleMachine.equipmentParams.pushAxisCode',
    key: 'pushAxisCode',
  },
  // 摆动轴
  {
    title: 'sideHoleMachine.equipmentParams.swingAxisCode',
    key: 'swingAxisCode',
  },
]
