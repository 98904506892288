<template>
  <div class="container">
    <div class="tag_title">
      <span>{{ $t('sideHoleMachine.tagPool') }}</span>
      <span>{{ $t('sideHoleMachine.dragTip') }}</span>
    </div>
    <div class="tag_warp" v-for="(items, index) in tagList" :key="index">
      <div class="tag_box flex flex-cross--center">
        <span :class="[items.title ? 'tag_item_title' : '']">{{
          items.title
        }}</span>
        <div class="tags">
          <span
            v-for="(item, idx) in items.arr"
            :key="item.id"
            draggable="true"
            :class="`tag-item${item.id}`"
            :data-clipboard-text="'{' + item.label_name_show + '}'"
            @dragstart.stop="startDrag($event, item)"
            @click="handleSelect(item)"
            :id="`engraving_setting_exportSetting_dragTag_${index}_${idx}`"
            >{{ item.label_name_show }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['tagList', 'dropData'],
  methods: {
    startDrag(e, val) {
      this.$emit('update:dropData', { ...val })
      this.$nextTick(() => {
        e.dataTransfer.setData('Text', e.target.classList[0])
      })
      this.$emit('dragStart', val)
    },
    handleSelect(item) {
      this.$emit('selectChange', `{${item.label_name_show}}`)
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  .tag_title {
    padding: 10px 20px;
    background: #f0f0f0;
    span:nth-child(1) {
      font-weight: 600;
      font-size: 20px;
    }
    span:nth-child(2) {
      color: #7b7b7b;
    }
  }
  .tag_warp {
    padding: 20px;
    border: 1px solid #f0f0f0;
    border-top: none;
    .tag_box {
      .tag_item_title {
        width: 15%;
        padding: 0 5px;
        text-align: left;
      }
      .tags {
        width: 85%;
        span {
          display: inline-block;
          margin: 10px;
          padding: 5px 10px;
          background-color: #eaf4f6;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
