import { ColumnType } from '../util/types'

export const knivesColumns: ColumnType[] = [
  // 编号
  {
    label: 'sideHoleMachine.knifes.number',
    props: 'knifeName',
    canEdit: true,
    width: 90,
    align: 'center',
  },
  // 刀宽
  {
    label: 'sideHoleMachine.knifes.diameter',
    props: 'diameter',
    canEdit: true,
    width: 90,
    isInputCheck: true,
    align: 'center',
  },
  // 换刀开始代码
  {
    label: 'sideHoleMachine.knifes.startCode',
    props: 'startCode',
    canEdit: true,
    isTextarea: true,
    align: 'center',
  },
  // 换刀结束代码
  {
    label: 'sideHoleMachine.knifes.endCode',
    props: 'endCode',
    canEdit: true,
    isTextarea: true,
    align: 'center',
  },
]

export const codeList = [
  // 设备启动代码
  {
    title: 'sideHoleMachine.equipmentParams.equipStartCode',
    key: 'beginCode',
  },
  // 设备暂停代码
  {
    title: 'sideHoleMachine.equipmentParams.equipPauseCode',
    key: 'pauseCode',
  },
  // 设备结束代码
  {
    title: 'sideHoleMachine.equipmentParams.equipEndCode',
    key: 'endCode',
  },
  // 左面开始代码
  {
    title: 'sideHoleMachine.equipmentParams.leftTurnCode',
    key: 'leftTurnCode',
  },
  // 左面结束代码
  {
    title: 'sideHoleMachine.equipmentParams.leftTurnEndCode',
    key: 'leftTurnEndCode',
  },
  // 下面开始代码
  {
    title: 'sideHoleMachine.equipmentParams.downTurnCode',
    key: 'backTurnCode',
  },
  // 下面结束代码
  {
    title: 'sideHoleMachine.equipmentParams.downTurnEndCode',
    key: 'backTurnEndCode',
  },
  // 右面开始代码
  {
    title: 'sideHoleMachine.equipmentParams.rightTurnCode',
    key: 'rightTurnCode',
  },
  // 右面结束代码
  {
    title: 'sideHoleMachine.equipmentParams.rightTurnEndCode',
    key: 'rightTurnEndCode',
  },
  // 上面开始代码
  {
    title: 'sideHoleMachine.equipmentParams.frontTurnCode',
    key: 'frontTurnCode',
  },
  // 上面结束代码
  {
    title: 'sideHoleMachine.equipmentParams.frontTurnEndCode',
    key: 'frontTurnEndCode',
  },
]

export const holeSetting = [
  // 加工命令
  {
    title: 'sideHoleMachine.workOrder',
    key: 'holeCutCode',
  },
]
export const slotSetting = [
  // 加工命令
  {
    title: 'sideHoleMachine.workOrder',
    key: 'slotCutCode',
  },
]

// 拉米诺设置
export const lmlSetting = [
  // 加工命令
  {
    title: 'sideHoleMachine.workOrder',
    key: 'lmlCutCode',
  },
]

// 榫卯设置
export const mtSetting = [
  // 直槽加工
  {
    title: 'sideHoleMachine.slot',
    key: 'mtLineCutCode',
  },
  // 圆弧加工
  {
    title: 'sideHoleMachine.cirWork',
    key: 'mtArcCutCode',
  },
]
