<template>
  <div class="front_working h100">
    <div class="h100 flex flex-dir--top">
      <div class="top_switch">
        <span style="font-weight: 700; font-size: 20px; color: black">{{
          $t('sideHoleMachine.enableFP')
        }}</span>
        <a-switch v-model="formData.verticalEnable" active-color="#18a8c7" />
      </div>
      <div
        class="front_working-content flex-box--1 flex h0 w100"
        v-if="formData.verticalEnable"
      >
        <AnchorNav :getContainer="() => container" :navs="navs" />
        <div class="wrap h100 y-auto x-auto" ref="wrap">
          <div style="min-width: 1500px">
            <div class="setItem">
              <span class="head-title" id="front-knife-table">刀库</span>
              <div class="main-table">
                <el-table
                  :data="formData.verticalKnives"
                  :cell-style="columnStyle"
                  :row-style="changeStyle"
                  :header-cell-style="headerStyle"
                  :header-row-style="headerRowStyle"
                  @cell-click="cellClick"
                  @row-click="choseKnife"
                  :row-class-name="tableRowClassName"
                  :cell-class-name="tableCellClassName"
                >
                  <el-table-column
                    v-for="(knife, indexs) in knivesColumns"
                    :key="indexs + '1'"
                    :label="knife.label"
                    :prop="knife.props"
                    :width="knife.width"
                  >
                    <template slot-scope="scope">
                      <div
                        v-if="
                          scope.row.index === rowIndex &&
                          scope.column.index === columnIndex &&
                          knife.props !== 'knifePurpose'
                        "
                      >
                        <el-input
                          v-model="scope.row[knife.props]"
                          ref="editInput"
                          :type="
                            knife.props == 'startCode' ||
                            knife.props == 'endCode'
                              ? 'textarea'
                              : 'text'
                          "
                          size="mini"
                          resize="none"
                          autosize
                          @blur="
                            inputBlur(
                              scope.row[knife.props],
                              scope.$index,
                              indexs,
                              scope.row
                            )
                          "
                          @focus="
                            getNowKnifeName(scope.row[knife.props], indexs)
                          "
                          @input="handleInputCheck(scope.row, knife.props)"
                        />
                      </div>
                      <div
                        v-else-if="knife.props === 'knifePurpose'"
                        class="knife-purpose flex"
                      >
                        <a-checkbox
                          value="holeAble"
                          v-model="scope.row.holeAble"
                          >{{ $t('cuttingDock.knives.punchHoles') }}</a-checkbox
                        >
                        <a-checkbox
                          value="slotAble"
                          v-model="scope.row.slotAble"
                          >{{ $t('cuttingDock.knives.pullSlots') }}</a-checkbox
                        >
                        <a-checkbox
                          value="stretchAble"
                          v-model="scope.row.stretchAble"
                          >{{ $t('common.stretch') }}</a-checkbox
                        >
                      </div>
                      <div
                        class="knives_type"
                        style="white-space: pre-line"
                        v-else
                      >
                        {{ scope.row[knife.props] }}
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <div class="operation-btn">
                <a-button @click="addNewKnife" class="mr12">{{
                  $t('cuttingDock.knives.insert')
                }}</a-button>
                <a-button @click="deleteKnife">{{
                  $t('cuttingDock.knives.delete')
                }}</a-button>
              </div>
            </div>
            <div class="setItem">
              <span class="head-title" id="front-punch-setting">{{
                $t('sideHoleMachine.holeSetting')
              }}</span>
              <div class="knife_set">
                <div class="left">
                  <span>{{ $t('sideHoleMachine.directives') }}</span>
                  <a-textarea
                    v-model="formData.verticalHoleSetting.templateString"
                    @drop="
                      dropOver(
                        $event,
                        'verticalHoleSetting',
                        'vertical_hole_setting'
                      )
                    "
                  ></a-textarea>
                </div>
                <div class="right">
                  <div class="tag_title">
                    <span>{{ $t('sideHoleMachine.tagPool') }}</span>
                    <span>{{ $t('sideHoleMachine.dragTip') }}</span>
                  </div>
                  <div
                    class="tag_warp"
                    v-for="(items, index) in settingData.vertical_hole_setting"
                    :key="index"
                  >
                    <span
                      v-for="item in items"
                      :key="item.id"
                      draggable="true"
                      class="m10 pv5 ph10"
                      :class="`tag-item${item.id}`"
                      :data-clipboard-text="'{' + item.label_name_show + '}'"
                      @dragstart.stop="startDrag($event, item)"
                      @dragend.stop="endDrag()"
                      >{{ item.label_name_show }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="setItem">
              <span class="head-title" id="front-slot-broaching">{{
                $t('sideHoleMachine.slotSetting')
              }}</span>
              <div class="knife_set">
                <div class="left">
                  <div>
                    <span>{{ $t('sideHoleMachine.directives') }}</span>
                    <a-textarea
                      v-model="formData.verticalSlotSetting.templateString"
                      @drop="
                        dropOver(
                          $event,
                          'verticalSlotSetting',
                          'vertical_slot_setting'
                        )
                      "
                    ></a-textarea>
                  </div>
                  <div style="margin: 20px 0">
                    <span>
                      <!-- 收刀指令 -->
                      {{ $t('sideHoleMachine.takeUpDirective') }}：
                    </span>
                    <a-textarea
                      v-model="formData.verticalRetractionCode"
                      :max-length="50"
                      :class="{ 'ant-textarea': isDrag }"
                      @dragover.prevent
                    ></a-textarea>
                  </div>
                </div>
                <div class="right">
                  <div class="tag_title">
                    <span>{{ $t('sideHoleMachine.tagPool') }}</span>
                    <span>{{ $t('sideHoleMachine.dragTip') }}</span>
                  </div>
                  <div
                    class="tag_warp"
                    v-for="(items, index) in settingData.vertical_slot_setting"
                    :key="index"
                  >
                    <span
                      v-for="item in items"
                      :key="item.id"
                      draggable="true"
                      class="m10 pv5 ph10"
                      :class="`tag-item${item.id}`"
                      :data-clipboard-text="'{' + item.label_name_show + '}'"
                      @dragstart.stop="startDrag($event, item)"
                      @dragend.stop="endDrag()"
                      >{{ item.label_name_show }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="setItem">
              <div class="split-cutting">
                <span style="margin-right: 20px">{{
                  $t('sideHoleMachine.workSeparately')
                }}</span>
                <a-switch v-model="formData.split_long_slot"> </a-switch>

                <span style="margin: 0 20px">{{
                  $t('sideHoleMachine.secondSlotLong')
                }}</span>
                <div style="display: inline-block; width: 60px">
                  <input-number
                    int
                    :decimalBit="2"
                    :value.sync="formData.second_slot_length"
                    id="frontWorking_second_slot_length"
                  >
                  </input-number>
                  <!-- <el-input
                  v-model="formData.second_slot_length"
                  ref="editInput"
                  type="string"
                  size="mini"
                  @input="handleInputSecondSlot"
                /> -->
                </div>
                <span>mm</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AnchorNav from '@/components/anchorNav/index.vue'
import { frontWorkingNavs } from '@/util/anchorNavData.ts'
import {
  regReplaceCuttingParamsDiameter,
  regReplaceSideHole,
} from '@/util/regReplace'

import inputNumber from '../common/input-number/src/input-number.vue'

export default {
  components: {
    inputNumber,
    AnchorNav,
  },
  props: {
    obj: {
      type: [Array, Object],
      default: () => {},
    },
    settingData: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      knivesColumns: [
        {
          label: this.$t('cuttingDock.knives.number'),
          props: 'id',
          width: 90,
          isCheck: false,
        },
        {
          label: `${this.$t('cuttingDock.knives.diameter')}`,
          props: 'diameter',
          width: 90,
          isCheck: false,
        },
        {
          label: this.$t('cuttingDock.knives.use'),
          props: 'knifePurpose',
          width: 160,
          isCheck: false,
        },
        {
          label: this.$t('cuttingDock.knives.xOffset'),
          props: 'x_offset',
          isCheck: false,
        },
        {
          label: this.$t('cuttingDock.knives.yOffset'),
          props: 'y_offset',
          isCheck: false,
        },
        {
          label: this.$t('cuttingDock.knives.zOffset'),
          props: 'z_offset',
          isCheck: false,
        },
        {
          label: this.$t('cuttingDock.knives.startCode'),
          props: 'startCode',
          isCheck: false,
        },
        {
          label: this.$t('cuttingDock.knives.endCode'),
          props: 'endCode',
          isCheck: false,
        },
      ],
      navs: frontWorkingNavs,
      formData: {
        holeKnife: '',
        slotKnife: '',
        verticalEnable: false,
        verticalKnives: [],
        verticalHoleSetting: {
          headerIds: {},
          templateString: '',
        },
        verticalRetractionCode: '', //收刀指令
        verticalSlotSetting: {
          headerIds: {},
          templateString: '',
        },
        split_long_slot: true,
        second_slot_length: 0,
      },
      // 记录行索引
      rowIndex: -1,
      // 记录列索引
      columnIndex: -1,
      currentRow: {},
      dragWord: '',
      // 记录当前刀具编号名
      copyKnifeId: '',
      isDrag: false, //记录当前是否在拖拽
    }
  },
  computed: {
    container() {
      return this.$refs.wrap
    },
  },
  methods: {
    handleInputSecondSlot() {
      this.formData.second_slot_length =
        this.formData.second_slot_length.replace(/[^0-9.]/g, '')
    },
    handlePrevent(e) {
      e.preventDefault()
    },
    // 更改表头单元格样式
    headerStyle({ row, column }) {
      row
      if (column.property === 'id') {
        return 'padding-left: 16px; box-sizing: border-box;background: #fafafa;'
      }
      return 'background: #fafafa'
    },
    // 更改单元格样式
    columnStyle({ row, column }) {
      if (column.property === 'id') {
        row
        return 'padding-left: 16px;'
      }
    },
    // 更改行样式
    changeStyle({ row, rowIndex }) {
      row
      if (this.currentRow.index == rowIndex) {
        return {
          height: '54px',
          'border-top': '1px solid #EEEEEE',
          'font-size': '14px',
          color: '#000000',
          'font-weight': '400',
          background: 'rgba(24, 168, 199, 0.2)',
        }
      }
      return {
        height: '54px',
        'border-top': '1px solid #EEEEEE',
        'font-size': '14px',
        color: '#000000',
        'font-weight': '400',
      }
    },
    // 更改表头行样式
    headerRowStyle() {
      return {
        height: '54px',
        'font-size': '14px',
        color: '#000000',
        'font-weight': '500',
        'border-bottom': 'none',
      }
    },
    // 点击单元格切换成输入框
    cellClick(row, column) {
      // if(column.property == 'id'){
      //     return
      // }
      if (column.label === '用途') {
        return
      }
      this.rowIndex = row.index
      this.columnIndex = column.index
      this.$nextTick(() => {
        this.$refs['editInput'][0].focus()
      })
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    //把每一列的索引加到列数据中
    tableCellClassName({ column, columnIndex }) {
      column.index = columnIndex
    },
    choseKnife(row) {
      this.currentRow = row
    },
    getNowKnifeName(id, cellIndex) {
      if (!cellIndex) {
        this.copyKnifeId = id
      }
    },
    handleInputCheck(row, prop) {
      if (this.columnIndex == 1) {
        row[prop] = regReplaceSideHole(row[prop], this.columnIndex)
      } else if (
        this.columnIndex == 2 ||
        this.columnIndex == 3 ||
        this.columnIndex == 4
      ) {
        row[prop] = regReplaceSideHole(row[prop], this.columnIndex)
        if (!row[prop].indexOf('-') <= 0) {
          row[prop] =
            row[prop].indexOf('.') > 0
              ? row[prop].split('.')[0].substring(0, 4) +
                '.' +
                row[prop].split('.')[1]
              : row[prop].substring(0, 4)
        } else {
          row[prop] =
            row[prop].indexOf('.') > 0
              ? row[prop].split('.')[0].substring(0, 5) +
                '.' +
                row[prop].split('.')[1]
              : row[prop].substring(0, 5)
        }
      }
      // 刀径只能输入数字且不能为负数和0
      if (prop === 'diameter') {
        row[prop] = regReplaceCuttingParamsDiameter(row[prop])
      }
    },
    addNewKnife() {
      let data = {
        // 'T' + (this.recordKnivesNum + 1)
        id: '',
        diameter: '',
        xOffset: '',
        yOffset: '',
        zOffset: '',
        startCode: '',
        endCode: '',
        holeAble: '',
        slotAble: '',
        stretchAble: '',
        hornAble: '',
      }
      this.recordKnivesNum += 1
      this.formData.verticalKnives.push(data)
      this.rowIndex = this.formData.verticalKnives.length - 1
      this.columnIndex = 1
      this.$nextTick(() => {
        this.$refs['editInput'][0].focus()
      })
    },
    deleteKnife() {
      this.formData.verticalKnives.splice(
        this.formData.verticalKnives.findIndex(
          (v) => v.index == this.currentRow.index
        ),
        1
      )
    },
    dropOver(event, item, settingField) {
      // event.target.focus();
      if (
        this.settingData[settingField]
          .flat(1)
          .findIndex(
            (item) =>
              item?.label_name_show ===
              this.dragWord.substring(1, this.dragWord.length - 1)
          ) !== -1
      ) {
        this.formData[item].templateString += this.dragWord
      }

      this.$nextTick(() => {
        event.preventDefault()
        var data = event.dataTransfer.getData('Text')
        let s = document.querySelector(`.${data}`).cloneNode(true)
        event.target.appendChild(s)
      })
    },
    endDrag() {
      this.isDrag = false
    },
    // 拖拽标签
    startDrag(event, item) {
      this.isDrag = true
      this.dragWord = `{${item.label_name_show}}`
      this.$nextTick(() => {
        event.dataTransfer.setData('Text', event.target.classList[0])
      })
    },
    inputBlur(e, i, cellIndex, row) {
      // 查询刀具编号是否重复，重复清空
      if (!cellIndex) {
        let saveKnifeId = []
        this.formData.verticalKnives.forEach((item, index) => {
          if (i != index) {
            saveKnifeId.push(item.id)
          }
        })
        if (saveKnifeId.indexOf(e) != -1) {
          this.$message({
            message: '编号重复，请重新设置',
            duration: 1000,
          })
          this.formData.verticalKnives[i].id = this.copyKnifeId
        }
      }

      this.rowIndex = -1
      this.columnIndex = -1
      // 失去焦点判断刀直径是否为0
      if (row['diameter'] === 0) {
        row['diameter'] = ''
      }
      if (row['diameter']) {
        row['diameter'] = Number(row['diameter'])
      }
    },
  },
  watch: {
    obj() {
      let s = JSON.parse(JSON.stringify(this.obj))
      const keys = Object.keys(this.formData)
      keys.forEach((key) => {
        this.formData[key] = s[key]
      })
    },
  },
  mounted() {
    const secondSlotLengthRef = document.getElementById(
      'frontWorking_second_slot_length'
    )
    if (secondSlotLengthRef)
      secondSlotLengthRef.addEventListener('dragover', (event) => {
        event.preventDefault()
      })
  },
}
</script>

<style lang="less" scoped>
@import '~@/style/engraving.less';
.ant-textarea {
  background: #f0f0f0;
}
.front_working {
  // width: 1671px;
  // margin: 16px;
  background-color: #fff;
  .top_switch {
    display: flex;
    align-items: center;
    height: 80px;
    padding-left: 30px;
    background-color: #fff;
    /deep/ .el-switch.is-checked {
      .el-switch__core::after {
        margin-left: -21px;
      }
    }
    /deep/ .el-switch__label span {
      font-weight: 700;
      font-size: 20px;
    }
    /deep/ .el-switch__label.is-active {
      color: #000;
    }
    /deep/ .el-switch__core {
      width: 50px !important;
      height: 24px;
      border-radius: 12px;
    }
    /deep/ .el-switch__core::after {
      width: 20px;
      height: 20px;
    }
  }
  &-content {
    .wrap {
      .navRightWrapWidth();
      .setItem {
        .split-cutting {
          display: flex;
          align-items: center;
        }
        .main-table {
          overflow-y: auto;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          width: 60%;
          max-height: 407px;
          padding: 24px;
          .knife-purpose {
            flex-wrap: wrap;
            /deep/.ant-checkbox-wrapper {
              margin-left: 0;
              &:first-child {
                margin-right: 22px;
              }
              &:nth-child(3) {
                margin-right: 8px;
              }
            }
            /deep/.ant-checkbox + span {
              padding-right: 0;
            }
          }
        }
        .operation-btn {
          box-sizing: border-box;
          padding: 24px;
        }
        .text-area {
          display: flex;
          flex-wrap: wrap;
          box-sizing: border-box;
          padding: 24px 24px 0 24px;
          div {
            margin-bottom: 24px;
            span {
              color: rgba(0, 0, 0, 0.8);
              font-weight: 600;
              cursor: default;
            }
            textarea {
              display: block;
              width: 466px;
              height: 113px;
              margin-top: 8px;
              border: 1px solid #dddddd;
              border-radius: 4px;
              resize: none;
            }
          }
          div:nth-child(2n + 1) {
            margin-right: 128px;
          }
          .container {
            width: 466px;
            .tag_title {
              margin: 0;
              padding: 10px 20px;
              background: #f0f0f0;
              & span:first-child {
                font-weight: 600;
                font-size: 20px;
              }
              & span:last-child {
                color: #7b7b7b;
              }
            }
            .tag_warp {
              margin: 0;
              padding: 20px;
              border: 1px solid #f0f0f0;
              > span {
                display: inline-block;
                font-weight: 400;
                background: #eaf4f6;
                cursor: pointer;
              }
            }
          }
        }
        .bore_holeSet {
          box-sizing: border-box;
          padding: 24px 24px 0 24px;
          > span {
            color: rgba(0, 0, 0, 0.8);
            font-weight: 600;
            cursor: default;
          }
          .hole_set_wap {
            position: relative;
            padding: 20px 10px;
            border: 1px solid #ddd;
            > div {
              > div {
                display: flex;
                align-items: center;
                justify-content: center;
                &.mid {
                  .hole_img {
                    padding: 20px;
                  }
                }
                .ant-input {
                  width: 200px;
                  resize: none;
                }
                .code_box {
                  div {
                    display: flex;
                    align-items: center;
                  }
                  > div:nth-child(1) {
                    margin-bottom: 20px;
                  }
                }
              }
            }
          }
        }
        .knife_set {
          display: flex;
          box-sizing: border-box;
          padding: 24px 24px 0 24px;
          > div {
            > span {
              color: rgba(0, 0, 0, 0.8);
              font-weight: 600;
              cursor: default;
            }
            textarea {
              display: block;
              width: 466px;
              height: 113px;
              margin-top: 8px;
              border: 1px solid #dddddd;
              border-radius: 4px;
              resize: none;
            }
            &.left {
              width: 30%;
              margin-right: 50px;
              .chooseKnife {
                margin-bottom: 20px;
                span {
                  color: rgba(0, 0, 0, 0.8);
                  font-weight: 600;
                }
              }
            }
            &.right {
              width: 50%;
              .tag_title {
                padding: 10px 20px;
                background: #f0f0f0;
                span:nth-child(1) {
                  font-weight: 600;
                  font-size: 20px;
                }
                span:nth-child(2) {
                  color: #7b7b7b;
                }
              }
              .tag_warp {
                padding: 20px;
                border: 1px solid #f0f0f0;
                border-top: none;
                > span {
                  display: inline-block;
                  background-color: #eaf4f6;
                  cursor: pointer;
                }
              }
            }
          }
        }
        .coor {
          box-sizing: border-box;
          padding: 24px 24px 0 24px;
          > div {
            margin-bottom: 20px;
          }
        }
        .suffix {
          box-sizing: border-box;
          padding: 24px 24px 0 24px;
          .ant-input {
            width: 100px;
          }
        }
      }
    }
  }
}
</style>
