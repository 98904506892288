<template>
  <div class="old_setting w100 h100 x-auto color-0" style="font-weight: 500">
    <div class="top_switch">
      <span style="font-weight: 700; font-size: 20px; color: black">{{
        $t('sideHoleMachine.enableOld')
      }}</span>
      <a-switch v-model="isOn" active-color="#1989fa" />
    </div>
    <div class="old_setting-content" v-show="isOn">
      <a-radio-group v-model="currentSetting" @change="handleChangeSetting">
        <a-radio value="default">{{ $t('sideHoleMachine.default') }}</a-radio>
        <a-radio value="自定义一">{{
          $t('sideHoleMachine.customizable1')
        }}</a-radio>
        <a-radio value="自定义二">{{
          $t('sideHoleMachine.customizable2')
        }}</a-radio>
        <a-radio value="自定义三">{{
          $t('sideHoleMachine.customizable3')
        }}</a-radio>
        <a-radio value="自定义四">{{
          $t('sideHoleMachine.customizable4')
        }}</a-radio>
      </a-radio-group>
      <div class="code_list y-auto">
        <component
          :is="currentComponent"
          :data="formData"
          @onDataChange="handleDataChange"
        ></component>
      </div>
    </div>
  </div>
</template>

<script>
import CustomFirst from './module/customFirst.vue'
import CustomFourth from './module/customFourth.vue'
import CustomSecond from './module/customSecond.vue'
import CustomThird from './module/customThird.vue'
import DefualtModule from './module/defualtModule.vue'

export default {
  props: {
    obj: {
      type: [Object, String],
      required: true,
    },
    enabled: {
      type: [Boolean, String],
      required: true,
    },
  },
  data() {
    return {
      isOn: false,
      currentSetting: 'default',
      componentList: new Map([
        ['default', 'DefualtModule'],
        ['自定义一', 'CustomFirst'],
        ['自定义二', 'CustomSecond'],
        ['自定义三', 'CustomThird'],
        ['自定义四', 'CustomFourth'],
      ]),
      currentComponent: 'DefualtModule',
      formData: {},
    }
  },
  components: {
    DefualtModule,
    CustomFirst,
    CustomSecond,
    CustomThird,
    CustomFourth,
  },
  methods: {
    handleChangeSetting() {
      this.currentComponent = this.componentList.get(this.currentSetting)
      if (this.currentSetting == 'default') {
        this.formData.style = ''
        this.formData.enabled = false
      } else {
        this.formData.style = this.currentSetting
        this.formData.enabled = true
      }
    },
    handleDataChange(formData) {
      switch (this.currentComponent) {
        case 'CustomFirst':
          for (const key in formData) {
            this.formData[key] = formData[key]
          }
          break
        case 'CustomSecond':
          for (const key in formData) {
            if (key != 'knives') {
              this.formData.settingV2[key] = formData[key]
            } else {
              this.formData[key] = formData[key]
            }
          }
          break
        case 'CustomThird':
          for (const key in formData) {
            this.formData.settingV3[key] = formData[key]
          }
          break
        case 'CustomFourth':
          for (const key in formData) {
            this.formData.settingV4[key] = formData[key]
          }
          break
        default:
          break
      }
    },
  },
  watch: {
    obj: {
      immediate: true,
      handler(val) {
        if (val) {
          this.formData = val
          this.currentSetting = val.enabled
            ? val.style
              ? val.style
              : 'default'
            : 'default'
          this.currentComponent = this.componentList.get(this.currentSetting)
        }
      },
    },
    enabled: {
      immediate: true,
      handler(val) {
        this.isOn = val
      },
    },
  },
}
</script>

<style scoped lang="less">
.old_setting {
  padding-bottom: 50px;
  overflow-y: hidden;
  background-color: #fff;
  .top_switch {
    display: flex;
    align-items: center;
    height: 80px;
    padding-left: 30px;
    background-color: #fff;
    /deep/ .el-switch__label span {
      font-weight: 700;
      font-size: 20px;
    }
    /deep/ .el-switch__label.is-active {
      color: #000;
    }
    /deep/ .el-switch__core {
      width: 50px !important;
      height: 24px;
      border-radius: 12px;
    }
    /deep/ .el-switch__core::after {
      width: 20px;
      height: 20px;
    }
  }
  &-content {
    width: 95%;
    height: 95%;
    margin-left: 30px;
    .ant-radio-group {
      display: flex;
      /deep/.ant-radio-wrapper {
        display: flex;
        align-items: center;
        color: #000;
        font-weight: 500;
        span {
          font-size: 18px;
        }
      }
    }
    .code_list {
      height: 90%;
    }
  }
}
</style>
