export class Knif {
  public static fromFirstSetting(values: any) {
    return new Knif({
      id: values.id,
      diameter: values.diameter,
      type: values.type,
      moveSpeed: values.moveSpeed,
      doSpeed: values.doSpeed,
      startCode: values.startCode,
      endCode: values.endCode,
    })
  }
  public static fromThirdSetting(values: any) {
    return new Knif({
      knifeName: values.knifeName,
      diameter: values.diameter,
      moveSpeed: values.moveSpeed,
      doSpeed: values.doSpeed,
      startCode: values.startCode,
      endCode: values.endCode,
      holeable: Boolean(values.holeable),
      slotable: Boolean(values.slotable),
      millable: Boolean(values.millable),
      appoint: Boolean(values.appoint),
      webId: values.webId,
    })
  }
  public static fromFourthSetting(values: any) {
    return new Knif({
      knifeName: values.knifeName,
      diameter: values.diameter,
      startCode: values.startCode,
      endCode: values.endCode,
    })
  }

  public id: string = ''
  public knifeName: string = ''
  public diameter: string = ''
  public type: string = ''
  public moveSpeed: string = ''
  public doSpeed: string = ''
  public startCode: string = ''
  public endCode: string = ''
  public appoint: boolean = false
  public holeable: boolean = false
  public slotable: boolean = false
  public millable: boolean = false
  public webId: string = ''

  constructor(props: Partial<Knif>) {
    Object.assign(this, props)
  }
}

export interface ColumnType {
  label: string
  props: string
  canEdit?: boolean
  isTextarea?: boolean
  width?: number
  isCustom?: boolean
  align?: 'left' | 'center' | 'right'
  isInputCheck?: boolean
}
